<template>
  <div>
    <table class="table table-bordered table-warning mb-0">
      <thead>
        <tr class="text-dark">
          <th class="text-center">ตั้งค่ารับของ</th>
          <th width="13%" v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
          <th width="30" class="text-center">แก้ไข</th>
        </tr>
      </thead>
      <tbody>
        <tr class="alert-warning bg-light">
          <td class="text-center">
            <h6 class="text-primary mb-0">รับของ</h6>
            <span class="text-secondary">(0 = ไม่รับ)</span>
          </td>
          <td v-for="bet in marketOpenBets" :key="bet.code" class="text-center">
            {{ receive[bet.code] | numberFormat }}
          </td>
          <td class="text-center">
            <button :disabled="!permSetting" class="btn btn-sm btn-outline-primary" @click="edit"><i class="far fa-edit"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <RoundReceiveModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import RoundReceiveModal from './RoundReceiveModal'

const defaultReceive = {
  threeNumberTop: 0,
  threeNumberTode: 0,
  twoNumberTop: 0,
  twoNumberBottom: 0,
  runTop: 0,
  runBottom: 0
}

export default {
  name: 'RoundReceiveTable',
  components: {
    RoundReceiveModal
  },
  props: ['data'],
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    receive() {
      return {
        ...defaultReceive,
        ...this.data?.receive
      }
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.RoundReceive === 'w'
    }
  },
  methods: {
    edit() {
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    },
  },
  filters: {
    numberFormat(num) {
      if(!num)
        return 0

      return parseFloat(num).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
}
</style>
