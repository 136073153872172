<template>
  <div :ref="refDiv" :id="refDiv" class="card border-info">
    <div class="card-header p-2 bg-info text-light">{{ bet.text }}</div>
    <div class="card-body p-2">
      <b-input-group v-if="permSetting">
        <b-form-input
          :id="refDiv+'-number'"
          type="text"
          placeholder="หมายเลข"
          :maxlength="maxlength"
          v-on:keydown="keynumber"
          v-model="input.number"
        ></b-form-input>
        <b-form-input
          :id="refDiv+'-amount'"
          type="text"
          :number="true"
          placeholder="จำนวน"
          v-on:keydown="keynumber"
          v-model="input.amount"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="saveReceiveNumber"><i class="fas fa-plus"></i></b-button>
        </b-input-group-append>
      </b-input-group>

      <div class="list-numbers mt-1">
        <div v-for="item in items" class="list-number mb-1 p-1 alert-info">
          <div>
            <b-form-checkbox
              :disabled="!permSetting"
              v-model="item.checked"
              :value="true"
              :unchecked-value="false"
            />
          </div>
          <div>
            <span class="badge badge-info">{{ item.number }}</span>
          </div>
          <div>{{ item.amount | numberFormat }}</div>
          <div class="text-right">
            <button class="btn btn-sm btn-outline-danger py-0" @click="deleteNumbers([item.number])"><i class="fas fa-trash-alt"></i></button>
          </div>
        </div>
      </div>

      <b-input-group v-if="permSetting" class="mt-2">
        <b-input-group-prepend is-text>
          <b-form-checkbox
            v-model="inputAll.checked"
            :value="true"
            :unchecked-value="false"
          />
        </b-input-group-prepend>
        <b-form-input type="text" :number="true" v-model="inputAll.amount"></b-form-input>
        <b-input-group-append>
          <b-button variant="success" @click="saveBulk"><i class="far fa-save"></i></b-button>
          <b-button variant="danger" @click="deleteBulk"><i class="fas fa-trash-alt"></i></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import RoundService from "@/services/RoundService"
import cAlert from '@/helpers/alert'
import _ from 'lodash'

export default {
  name: 'ReceiveNumbers',
  props: ['bet', 'data'],
  data() {
    return {
      input: {
        number: '',
        amount: ''
      },
      inputAll: {
        checked: false,
        amount: ''
      },
      items: []
    }
  },
  computed: {
    refDiv() {
      return `box-${this.bet.code}`
    },
    maxlength() {
      return {
        threeNumberTop: 3,
        threeNumberTode: 3,
        twoNumberTop: 2,
        twoNumberBottom: 2,
        runTop: 1,
        runBottom: 1
      }[this.bet.code]
    },
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    numbers() {
      return this.data?.receiveNumbers[this.bet.code] || {}
    },
    checkAll() {
      return this.inputAll.checked
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.RoundReceive === 'w'
    }
  },
  watch: {
    numbers() {
      this.mapNumbers()
    },
    checkAll() {
      this.items = this.items.map((item)=>{
        return {
          ...item,
          checked: this.inputAll.checked
        }
      })
    }
  },
  methods: {
    keynumber(event) {
      if(['Enter'].includes(event.key)) {
        event.preventDefault()

        if(this.input.amount==='') {
          document.getElementById(this.refDiv+'-amount').focus()
        }else{
          this.saveReceiveNumber()
        }
      }else
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)) {
        event.preventDefault()
      }
    },
    mapNumbers() {
      this.items = []
      for(const number in this.numbers) {
        this.items.push({
          checked: false,
          number: number,
          amount: this.numbers[number]
        })
      }
    },
    saveReceiveNumber() {

      if(this.input.number==='' || this.input.amount==='') {
        return Swal.fire({
          text: 'กรุณากรอกข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      if(this.input.number.toString().length !== this.maxlength) {
        return Swal.fire({
          text: `กรุณากรอกตัวเลข ${this.maxlength} ตัว`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      let groupNumbers = [this.input.number]
      /**
       * 3 ตัวโต๊ด
       */
      if(this.bet.code === 'threeNumberTode') {
        groupNumbers = _.uniqBy(this.gThreeNumberTode(this.input.number))
      }

      RoundService.saveReceiveNumber({
        numbers: groupNumbers,
        amount: this.input.amount,
        roundId: this.roundId,
        code: this.bet.code
      })
      .then((response)=>{
        if(response.success) {
          this.input.number = ''
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เพิ่มข้อมูลเสร็จเรียบร้อย',
          })
          this.$emit('reload')
          document.getElementById(this.refDiv+'-number').focus()
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'เพิ่มข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    saveBulk() {
      if(this.inputAll.amount === '') {
        return Swal.fire({
          text: 'กรุณากรอกข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      const numbers = this.items.filter((item)=>{
        return item.checked
      })
      .map((item)=>{
        return item.number
      })

      if(numbers.length === 0) {
        return Swal.fire({
          text: 'กรุณาเลือกข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      let groupNumbers = numbers
      /**
       * 3 ตัวโต๊ด
       */
      if(this.bet.code === 'threeNumberTode') {
        const allNumbers = numbers.reduce((ns, n)=>{
          return ns.concat(this.gThreeNumberTode(n))
        }, [])

        groupNumbers = _.uniqBy(allNumbers)
      }

      RoundService.saveReceiveNumber({
        numbers: groupNumbers,
        amount: this.inputAll.amount,
        roundId: this.roundId,
        code: this.bet.code
      })
      .then((response)=>{
        if(response.success) {
          this.inputAll.checked = false
          this.inputAll.amount = ''
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เพิ่มข้อมูลเสร็จเรียบร้อย',
          })
          this.$emit('reload')
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    deleteNumbers(numbers=[]) {
      if(!numbers.length) {
        return Swal.fire({
          text: 'กรุณาเลือกข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      Swal.fire({
        title: 'ยืนยันลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {

          let groupNumbers = numbers
          /**
           * 3 ตัวโต๊ด
           */
          if(this.bet.code === 'threeNumberTode') {
            const allNumbers = numbers.reduce((ns, n)=>{
              return ns.concat(this.gThreeNumberTode(n))
            }, [])

            groupNumbers = _.uniqBy(allNumbers)
          }

          RoundService.deleteNumbers({
            numbers: groupNumbers,
            roundId: this.roundId,
            code: this.bet.code
          })
          .then((response)=>{
            if(response.success) {
              this.inputAll.checked = false
              this.inputAll.amount = ''
              this.$emit('reload')
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            cAlert({
              ...e,
              title: 'ผิดพลาด!',
              text: 'ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    deleteBulk() {
      const numbers = this.items.filter((item)=>{
        return item.checked
      })
      .map((item)=>{
        return item.number
      })

      this.deleteNumbers(numbers)
    },
    gThreeNumberTode(number) {
      if(!number)
        return []

      const n = number.slice('')
      const numbers = [
        `${n[0]}${n[1]}${n[2]}`,
        `${n[0]}${n[2]}${n[1]}`,
        `${n[1]}${n[0]}${n[2]}`,
        `${n[1]}${n[2]}${n[0]}`,
        `${n[2]}${n[1]}${n[0]}`,
        `${n[2]}${n[0]}${n[1]}`,
      ]

      return _.uniqBy(numbers)
    }
  },
  mounted() {
    this.mapNumbers()
  },
  filters: {
    numberFormat(num) {
      if(!num)
        return 0

      return parseFloat(num).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
<style lang="scss" scoped>
.list-numbers {
  .list-number {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    align-items: center;

    div {
      width: 25%;

      .badge {
        font-size: 100%;
        font-weight: normal;
      }
    }
  }
}
</style>
